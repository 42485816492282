<template>
    <div class="fill-height mx-auto">
        <screening
            v-if="pageState === 'screening'"
            @confirm="
                (input) => {
                    nano.ans.screeningWordInput = input
                    pageState = 'start'
                }
            "
        ></screening>
        <start
            v-if="pageState === 'start'"
            @confirm="pageState = 'judgement'"
        ></start>
        <judgement
            v-if="pageState === 'judgement' && imgSrcs !== null"
            :key="`trial-${trial}`"
            :show-first-blank="trial !== 0"
            :img-srcs="imgSrcs"
            @image-select="saveAndNext"
        ></judgement>
    </div>
</template>

<script>
import nanoMixIn from '@/mixins/nano.js'
import Screening from './Screening'
import Start from './Start'
import Judgement from './Judgement'
import env from '../../env.json'

const DEBUG_NUM_TRIALS = 10
const DEBUG = true

function dummy(v) {
    return v
}
function getAccessblePath(path) {
    return `/static/qst-202302/images/${env.imageSet}/${path}`
}

export default {
    mixins: [nanoMixIn],
    components: { Screening, Start, Judgement },
    data: () => ({
        trial: 0,
        allTrialData: [],
        pageState: 'screening',

        defaultNanoProps: {
            run: 0
        },
        defaultNanoAnswers: {
            run: null,
            screeningWordInput: '',
            response: []
        }
    }),
    computed: {
        trialData() {
            return this.allTrialData[this.trial] || null
        },
        imgSrcs() {
            if (this.trialData) {
                const { true_pos, ...imgPaths } = this.trialData
                dummy(true_pos)
                return Object.entries(imgPaths).map(([key, path]) => {
                    return [key, getAccessblePath(path)]
                })
            } else return null
        }
    },
    methods: {
        setPageState(state) {
            this.pageState = state
        },
        saveAndNext(selectedPos, responseTime) {
            this.nano.ans.response[this.trial] = {
                trial: this.trial,
                truePos: parseInt(this.trialData.true_pos),
                path: Object.fromEntries(this.imgSrcs),
                selectedPos,
                responseTime
            }
            const numTrials = DEBUG
                ? DEBUG_NUM_TRIALS
                : this.allTrialData.length
            if (this.trial < numTrials - 1) this.trial += 1
            else this.submit()
        }
    },
    async mounted() {
        const imgList = await import(
            `../../assets/image_lists/order__task_${env.task}.json`
        )
        this.allTrialData = imgList[this.nano.props.run]
        this.nano.ans.run = this.nano.props.run
    }
}
</script>
<style>
.fill-width {
    width: 100%;
}
</style>
